import React, { Component } from 'react'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import { Box } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import { Integrations } from '@sentry/tracing'
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import { setLocale } from 'yup'
import { translation } from 'constants/locale'

import Routes from 'app/index'
import CoreProvider from 'components/Provider'

import 'moment/locale/pt-br'

import { DownloadLink } from './styles'

setLocale(translation)

const analyticsKey = process.env.REACT_APP_ANALYTICS_KEY

if (analyticsKey) {
  ReactGA.initialize(analyticsKey)
}

Sentry.init({
  dsn: `${process.env.REACT_APP_FRONTEND_SENTRY_CREDITOR_BASE_DSN}`,
  integrations: [new Integrations.BrowserTracing()],
})

const generateClassName = createGenerateClassName({
  productionPrefix: 'jss',
  disableGlobal: true,
})

export default class App extends Component {
  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  getNotAllowedNavigator() {
    const ua = window.navigator.userAgent
    return /MSIE|Trident/.test(ua)
  }

  render() {
    return (
      <StylesProvider injectFirst generateClassName={generateClassName}>
        {this.getNotAllowedNavigator() && (
          <Box
            bgcolor='error.main'
            textAlign='center'
            color='white'
            py={1}
            alignItems='center'
            justifyContent='center'
            display='flex'
          >
            <Warning />
            <Box fontWeight='bold' component='span' ml={2}>
              Browser Incompatível!
            </Box>
            <Box component='span' mr={1}>
              Alguns recursos podem não funcionar corretamente.
            </Box>
            <DownloadLink href='https://www.google.com/intl/pt-BR/chrome/' target='_blank'>
              Instalar navegador recomendado
            </DownloadLink>
          </Box>
        )}
        <CssBaseline />
        <CoreProvider>
          <Router>
            <Routes />
          </Router>
        </CoreProvider>
      </StylesProvider>
    )
  }
}
