import IAddress from './address'
import { IDocument } from './document'
import IPayment from './payment'
import { IDisposal } from './disposal'
import { SALESCHANNEL_SUBMODULES, TSubmodules } from 'interfaces/submodules'
import { validate } from 'components/Forms'
import * as yup from 'yup'

interface Company {
  id?: string
  nome_fantasia: string
  cnpj: string
  telefone: string
}
export interface ISalesChannelForm {
  id?: string
  nome: string
  email: string
  sobrenome: string
  data_nascimento: string
  dependentes: number
  nascimento_uf: string
  sexo: string
  estado_civil: string
  nome_mae: string
  nome_pai: string
  canal: Company
  endereco: Omit<IAddress, 'pais'>
  dados_bancarios: Omit<
    IPayment,
    | 'praca'
    | 'nome_titular'
    | 'cpf_responsavel'
    | 'cnpj_responsavel'
    | 'cliente_desde'
    | 'banco_nome'
  >
  telefone_celular: string
  referencia_nome: string
  referencia_parentesco: string
  referencia_telefone: string
  cartao_credito: {
    bandeira: string
    limite: string | number
  }
  documento: IDocument
  emprego_cp: {
    salario: string
    tipo: string
    data_admissao: string
    classe_profissional: string
    atividade: string
    empresa: Company & {
      razao_social: string
    }
  }
  emprego_cdc: {
    salario: string
    tipo: string
    data_admissao: string
    classe_profissional: string
    atividade: string
    empresa: Company & {
      razao_social: string
    }
  }
  dados_conjugue: {
    nome: string
    cpf: string
    empresa: Company
  }
  alienacao?: IDisposal
}

export const validationSchemaCPCDC = (subModule: TSubmodules | undefined) => {
  return validate(
    yup.object().shape({
      cpf: yup.string().required(),
      email: yup.string().email().required(),
      nome: yup.string().required(),
      sobrenome: yup.string().required(),
      data_nascimento: yup.string().required(),
      dependentes: yup.number().required(),
      nacionalidade: yup.string().required(),
      nascimento_uf: yup.string().required(),
      sexo: yup.string().required(),
      estado_civil: yup.string().required(),
      nome_mae: yup.string().required(),
      nome_pai: yup.string().required(),
      endereco: yup.object().shape({
        cep: yup.string().required(),
        cidade: yup.string().required(),
        uf: yup.string().required(),
        bairro: yup.string().required(),
        numero: yup.string().required(),
        complemento: yup.string(),
        logradouro: yup.string().required(),
        residencia_tipo: yup.string().required(),
        residencia_tempo: yup.string().required(),
      }),
      cartao_credito: yup.object().shape({
        bandeira: yup.string().required(),
        limite: yup.number().required(),
      }),
      dados_bancarios: yup.object().shape({
        banco: yup.string().required(),
        tipo: yup.string().required(),
        agencia: yup.string().required(),
        agencia_dac: yup.string().required(),
        conta: yup.string().required(),
        conta_dac: yup.string().required(),
      }),
      dados_conjugue: yup.object().when('show_spouse', (value: string) =>
        value === 'false' || value === undefined
          ? yup.object().nullable()
          : yup.object().shape({
              cpf: yup.string().required(),
              empresa: yup.object().shape({
                nome_fantasia: yup.string().required(),
                telefone: yup.string().required(),
                cnpj: yup.string().required(),
              }),
              nome: yup.string().required(),
            })
      ),
      referencia_nome: yup.string().required(),
      referencia_parentesco: yup.string().required(),
      referencia_telefone: yup.string().required(),
      telefone_celular: yup.string().required(),
      documento: yup.object().shape({
        data_emissao: yup.string().required(),
        emissor: yup.string().required(),
        numero: yup.string().required(),
        tipo: yup.string().required(),
        uf: yup.string().required(),
      }),
      [subModule === SALESCHANNEL_SUBMODULES.CP ? 'emprego_cp' : 'emprego_cdc']: yup
        .object()
        .shape({
          atividade: yup.string().required(),
          classe_profissional: yup.string().required(),
          data_admissao: yup.string().required(),
          salario: yup.string().required(),
          tipo: yup.string().required(),
          empresa: yup.object().shape({
            nome_fantasia: yup.string().required(),
            telefone: yup.string().required(),
            cnpj: yup.string().required(),
            razao_social: yup.string().required(),
          }),
        }),
    })
  )
}

export const validationSchemaCDV = (subModule: TSubmodules | undefined) => {
  return validate(
    yup.object().shape({
      cpf: yup.string().required(),
      nome: yup.string().required(),
      sobrenome: yup.string().required(),
      data_nascimento: yup.string().required(),
      nacionalidade: yup.string().required(),
      sexo: yup.string().required(),
      estado_civil: yup.string().required(),
      nome_mae: yup.string().required(),
      endereco: yup.object().shape({
        cep: yup.string().required(),
        cidade: yup.string().required(),
        uf: yup.string().required(),
        bairro: yup.string().required(),
        numero: yup.string().required(),
        complemento: yup.string(),
        logradouro: yup.string().required(),
      }),
      emprego_cdc: yup.object().shape({
        salario: yup.string().required(),
      }),
      dados_bancarios: yup.object().shape({
        banco: yup.string().required(),
        tipo: yup.string().required(),
        agencia: yup.string().max(4).required(),
        agencia_dac: yup.string().max(1).required('Se não souber colocar zero'),
        conta: yup.string().required(),
        conta_dac: yup.string().max(1).required(),
      }),
      telefone_celular: yup.string().required(),
      email: yup.string().email().required(),
      documento: yup.object().shape({
        data_emissao: yup.string().required(),
        emissor: yup.string().required(),
        numero: yup.string().max(15).required(),
        tipo: yup.string().required(),
        uf: yup.string().required(),
      }),
      referencia_nome: yup.string().required(),
      referencia_parentesco: yup.string().required(),
      referencia_telefone: yup.string().required(),
    })
  )
}
