import Axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { spacing as spacingValue } from 'constants/css'
import { ICalcSimulate, IEntity, IReceivable } from 'interfaces'
import breakpoints from 'constants/breakpoints'
import IAddress from 'interfaces/address'
import { IErrorsResponse } from 'interfaces'

export const capitalize = (value: String | string | undefined): string => {
  if (!value) {
    return ''
  }
  return String(value)?.charAt(0)?.toUpperCase() + String(value)?.slice(1)
}

export const spacing = (size: number): string => {
  return `${size * spacingValue}px`
}

export const breakpoint = (size: 'sm' | 'md' | 'lg'): string => {
  return `${breakpoints[size]}px`
}

export const getFirstLetters = (word: string): String => {
  return (word || '')?.charAt(0)?.toUpperCase()
}

export const formatCurrency = (
  value: Number,
  options?: { style?: 'decimal' | 'currency' }
): string => {
  if (value === undefined) {
    return Number(0).toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: options?.style || 'currency',
    })
  }

  return Number(value).toLocaleString('pt-BR', {
    currency: 'BRL',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: options?.style || 'currency',
  })
}

export const formatDate = (date: string, format?: string, convertFormat?: string): string => {
  if (date) {
    const momentDate = moment(date, convertFormat)
    return momentDate.format(format || 'DD/MM/YYYY')
  }
  return '-'
}

export const formatPercent = (value: Number | String, abs: boolean = true): string => {
  if (abs && value) {
    return (Number(value) / 100).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'percent',
    })
  }
  if (value) {
    return Number(value).toLocaleString('pt-BR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'percent',
    })
  }
  return '0,00%'
}

export const parseCurrency = (value = 0) => {
  if (typeof value === 'number') {
    return value.toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }
  if (typeof value === 'string' && !isNaN(parseFloat(value))) {
    return parseFloat(value).toLocaleString('pt-BR', {
      currency: 'BRL',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
    })
  }

  return 'R$ 0,00'
}

export const formatPhoneNumber = (str: string) => {
  if (str) {
    const cleaned = str.replace(/(\+\d{2})/g, '')

    if (!cleaned) {
      return ''
    }

    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }

    if (!match && cleaned.length < 11) {
      return cleaned
    }
  }

  return 'Não informado'
}

export const removeNullKeys = (obj: any): Object => {
  return Object.keys(obj).reduce((result, key) => {
    const objValue = obj[key]
    if (objValue === null || objValue === '') {
      return result
    }
    if (objValue instanceof Object) {
      const keyValues = removeNullKeys(objValue)
      return {
        ...result,
        [key]: keyValues,
      }
    }
    return {
      ...result,
      [key]: objValue,
    }
  }, {})
}

export const getPasswordScore = (passwordScore: number) => {
  const scores = [
    [0, 'secondary', 'Senha inválida'],
    [25, 'secondary', 'Senha não permitida'],
    [50, 'primary', 'Senha média'],
    [75, 'primary', 'Senha boa'],
    [100, 'primary', 'Senha ótima'],
  ]
  return scores[passwordScore]
}

export const getCepInfos = (cep: string, change: (name: string, value?: any) => void) => {
  if (typeof cep === 'string' && cep.length === 9) {
    Axios({
      url: `${process.env.REACT_APP_CREDITOR_BASE_URL}/v3/correios/cep/consultar/${cep.replace(
        /[^0-9]/,
        ''
      )}`,
      method: 'GET',
    }).then((address: AxiosResponse<IAddress>) => {
      const { logradouro, complemento, bairro, localidade, uf } = address.data
      change('endereco.cidade', localidade)
      change('endereco.bairro', bairro)
      change('endereco.complemento', complemento)
      change('endereco.uf', uf)
      change('endereco.logradouro', logradouro)
    })
  }
}

export const formatErrors = (errors: IErrorsResponse): string | undefined => {
  if (errors) {
    return Object.keys(errors).reduce((result: string, key) => {
      const value = errors[key]
      if (typeof value === 'string' && key !== 'codigo') {
        return value
      }
      if (Array.isArray(value)) {
        return value[0]
      }
      return result
    }, '')
  }
  return ''
}

export const verifyLimits = (value: number, max: number, min: number) => {
  const maxValue = roundCurrencyValue(max)
  const minValue = roundCurrencyValue(min)
  if (value >= maxValue) {
    return maxValue
  }

  if (value <= minValue) {
    return minValue
  }

  return value
}

export const calcSimulate = ({
  values,
  factor,
  maxValue,
  minValue,
  maxValueParcel,
  minValueParcel,
  isParcel,
}: ICalcSimulate) => {
  const value = isParcel
    ? verifyLimits(values, maxValueParcel, minValueParcel)
    : verifyLimits(values, maxValue, minValue)

  if (isParcel) {
    return verifyLimits(roundCurrencyValue(value * factor.fator), maxValue, minValue)
  }

  return verifyLimits(roundCurrencyValue(value / factor.fator), maxValueParcel, minValueParcel)
}

export const roundCurrencyValue = (num: number) => {
  return Math.round(num * 100) / 100
}

export const isModality = (entity: IEntity) => {
  if (entity?.subModule === 'CP') return 'CP'
  if (entity?.subModule === 'CDC') return 'CDC'
  if (entity?.subModule === 'CDCVEICULO') return 'CDCVEICULO'
  if (entity?.subModule === 'PJ') return 'PJ'
  if (entity.id === 'corban') return 'CORBAN'
  return 'CEP'
}

export const setItem = (key: string, value: string): void => {
  localStorage.setItem(key, value)
}

export const getItem = (key: string): string => {
  return localStorage.getItem(key) || ''
}

export const removeItem = (key: string): void => {
  localStorage.removeItem(key)
}

export const formatCurrencyNumber = (value: number) => {
  return (value / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  })
}
export const showInformationsAboutLot = (item: IReceivable, dontPay?: boolean) => {
  if (
    item.lote &&
    item.lote.status !== 'conciliado' &&
    item.lote.status !== 'rascunho' &&
    item.lote.status !== 'conciliado-automaticamente' &&
    item.pagamentos?.length === 0 &&
    dontPay
  ) {
    return true
  }
  return false
}
